import React, { useState, useEffect } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Nav from "./components/Nav";
import { Routes, Route, useNavigate } from "react-router-dom";
import StronaGłówna from "./pages/StronaGłówna";
import Onas from "./pages/Onas";
import Oferta from "./pages/Oferta";
import Proces from "./pages/Proces";
import Kontakt from "./pages/Kontakt";
import NotFound from "./pages/NotFound";
import Instagram from "./pages/Instagram";
import Footer from "./components/Footer";
import TransitionOverlay from "./components/TransitionOverlay";
import BezplatneKonsultacje from "./pages/BezplatneKonsultacje";
import Wycena from "./pages/Wycena";
import Cennik from "./components/Cennik";
import Banner from "./components/Banner";
import Price from "../src/static/images/price.svg";
import Calendar from "../src/static/images/calendar.svg";

function App() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // State to track button visibility
  const navigate = useNavigate();

  // Function to handle page navigation with overlay
  const handleNavigation = (path) => {
    setIsOverlayVisible(true);
    setTimeout(() => {
      navigate(path);
    }, 500); // Duration of animation
  };

  // Function to handle scroll and display buttons after scrolling past 100vh
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="w-full overflow-hidden">
      <TransitionOverlay
        isVisible={isOverlayVisible}
        onTransitionEnd={() => setIsOverlayVisible(false)}
        style={{ pointerEvents: isOverlayVisible ? "auto" : "none" }}
      />
      <Nav onNavigation={handleNavigation} />

      {/* Floating buttons, visible only after scrolling past 100vh */}
      {isVisible && (
        <div className="text-xl fixed right-0 top-[70%] z-50 flex flex-row items-end p-1 transform rotate-90 origin-right mr-[20px]">
          {/* Zakładka "Bezpłatne konsultacje" */}
          <button
            onClick={() => handleNavigation("/bezplatneKonsultacje")}
            className="bg-gold flex justify-center items-center text-darkGray p-2 rounded-xl shadow-md hover:bg-darkGold transition-colors duration-300 text-center whitespace-nowrap"
            style={{
              pointerEvents: "auto",
            }}
          >
            <img
              src={Calendar}
              alt="pricing qubit web"
              className="w-6 h-6 mb-1 pr-1"
            />
            Bezpłatne konsultacje
          </button>

          {/* Zakładka "Wycena" */}
          <button
            onClick={() => handleNavigation("/wycena")}
            className="ml-4 bg-gold flex justify-center items-center text-darkGray p-2 rounded-xl shadow-md hover:bg-darkGold transition-colors duration-300 text-center whitespace-nowrap"
            style={{
              pointerEvents: "auto",
            }}
          >
            <img
              src={Price}
              alt="calendar pick date qubit web"
              className="w-6 h-6 mb-1 pr-1"
            />
            Wycena
          </button>
        </div>
      )}

      <Routes>
        <Route path="/" element={<StronaGłówna />} />
        <Route path="/o-nas" element={<Onas />} />
        <Route path="/oferta" element={<Oferta />} />
        <Route path="/proces" element={<Proces />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/wycena" element={<Wycena />} />
        <Route path="/instagram" element={<Instagram />} />
        <Route
          path="/cennik"
          element={<Cennik onNavigate={handleNavigation} />}
        />
        <Route
          path="/bezplatneKonsultacje"
          element={<BezplatneKonsultacje />}
        />
        <Route path="/kontakt" element={<Banner />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
