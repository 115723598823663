import React from 'react'
import Banner from '../components/Banner'
import AboutTheTeam from '../components/AboutTheTeam'
import Header from "../components/Header"
import image from "../static/images/heroBanner.webp";


const Onas = () => {
  return (
    <div>
       <Header title="Skontaktuj się z nami" imageUrl={image} />
      <AboutTheTeam />
    <Banner />
    </div>
  )
}

export default Onas
