import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <section className=" bg-gold min-h-[20vh] text-darkGray py-8 px-6 md:px-12 flex flex-col md:flex-row items-center justify-between border-t-2 border-darkGray">
      <h1 className="text-[1.125rem] md:text-[1.5rem] lg:text-[1.5rem] mb-6 md:mb-0 text-center md:text-left md:mr-8">
        Zrób krok w stronę sukcesu. Skontaktuj się z nami i wyróżnij się na tle konkurencji!
      </h1>
      <Link to='/kontakt' 
        className="text-darkGray bg-gold hover:text-white border border-darkGray px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out text-center z-100"
      >
        Zapraszamy do kontaktu
      </Link>
    </section>
  );
}

export default Banner;
