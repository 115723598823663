import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Oferta = ({ blocks }) => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentSection = sectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <div
      id="offer-section"
      ref={sectionRef}
      className="px-5 sm:px-10 md:px-20 bg-lightBeige relative overflow-hidden"
    >
      <p className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-extralight py-8 md:py-16 text-center my-8 text-darkGray relative lg:font-Montserrat">
        Co oferujemy?
      </p>
      <div className="flex flex-col md:flex-row justify-center items-stretch">
        {blocks.map((block, index) => (
          <div
            key={index}
            className={`relative w-full md:w-1/2 lg:w-1/3 p-4 flex flex-col transition-block ${
              isVisible ? "active" : ""
            }`} // Apply transition-block for animation
            style={{ transitionDelay: `${index * 0.1}s` }} // Delay for animation
          >
            <div className="relative flex flex-col flex-1 border border-gold rounded-lg overflow-visible transform transition-transform duration-500 ease-in-out">
              <div
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-12 md:w-16 h-12 md:h-16 text-3xl md:text-5xl font-bold text-gold bg-lightBeige"
                style={{ zIndex: 10 }}
              >
                {index + 1}
              </div>
              <div className="flex-1 p-4 flex flex-col items-center text-center">
                <h2 className="text-2xl md:text-3xl py-6 text-darkGray font-bold mb-2">
                  {block.title}
                </h2>
                <p className="text-darkOlive text-xl">
                  {block.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8 md:mt-12 mb-8 md:mb-12 px-4 md:px-10 w-full">
        <Link
          to="/oferta"
          className="text-darkGray text-center bg-gold hover:text-white border px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
        >
          Chcesz dowiedzieć się więcej?
        </Link>
      </div>
    </div>
  );
};

export default Oferta;
